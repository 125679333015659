
/*
 * podcast.js
 * Author: perterpon.wang<perterpon.wang@bytedance.com>
 * Create: Fri Apr 26 2024 15:31:26 GMT+0800 (China Standard Time)
 */


import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CheckCircleTwoTone, TranslationOutlined } from '@ant-design/icons';
import { NodeIndexOutlined, PlusOutlined } from '@ant-design/icons';
import { Line } from '@ant-design/charts';
import { Flex, Tooltip, Descriptions, Progress, Upload, Image, Button, message, Space, Tabs, Table, Tag, Modal, Spin } from 'antd';
import fileSize from 'filesize';

import { requestData } from './request';

import { ImageSearch } from './image-search';

import moment from 'moment';

import { Segment } from './segment';


const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

async function loadData() {
  const res = await requestData('getPodcast');
  return res;
}

function GenerateImage({podcastId, onChoose}) {

  const [imgUrl, setImgUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  async function onGenerateImage() {

    setLoading(true);
    try {
      const url = await requestData('generateAICover', {
        podcastId,
      });
      setImgUrl(url);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Space>
        <Button type='primary' onClick={onGenerateImage}>Generate</Button>
        <Button type='primary' onClick={() => {
          onChoose(imgUrl);
        }}>Choose</Button>
      </Space>
      <Flex vertical justify='center'>
        <Spin spinning={loading}>
          <img className='generated-img' src={imgUrl} />
        </Spin>
      </Flex>
    </div>
  )
}

function RenderCover({item}) {
  const [previewImage, setPreviewImage] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const defaultFileList = [];
  if (item.cover) {
    defaultFileList.push({
      uid: '-1',
      name: 'cover',
      status: 'done',
      url: `https://cdn-pt.perterpon.com/lh-tts/audios/${item.cover}`
    });
  }
  const [fileList, setFileList] = useState(defaultFileList);

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  async function onPreview(file) {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || (file.preview));
    setPreviewOpen(true);
  }

  async function customRequest(option) {
    const base64 = await getBase64(option.file);
    await requestData('uploadPodcastCover', {
      podcastId: item.podcastId,
      audioId: item?.buildTask?.params?.audioId,
      showType: item.showType,
      cover: base64
    });
    option.onSuccess(`https://cdn-pt.perterpon.com/lh-tts/audios/${item.showType}/${item?.buildTask?.params?.audioId}/cover`);
  }

  const imageModal = useRef({});

  async function uploadImage(url) {
    try {
      const resData = await requestData('uploadPodcastCoverLink', {
        podcastId: item.podcastId,
        audioId: item?.buildTask?.params?.audioId,
        showType: item.showType,
        url: url,
      });
      setFileList([{
        uid: '-1',
        name: 'cover',
        status: 'done',
        url: `https://cdn-pt.perterpon.com/lh-tts/audios/${resData.path}`
      }]);
      imageModal?.current?.destroy();
    } catch (e) {
    } finally {
    }
  }

  async function onSearchImage() {
    imageModal.current = Modal.info({
      content: <ImageSearch onChoose={(image) => {
        uploadImage(image.link);
      }} query={item.title}></ImageSearch>,
      footer: null,
      title: item.title,
      width: '80%',
      closable: true
    });
  }

  async function onGenerateImage() {
    imageModal.current = Modal.info({
      content: <GenerateImage podcastId={item.podcastId} onChoose={(url) => {
        uploadImage(url);
      }} />,
      title: item.title,
      width: '80%',
      footer: null,
      closable: true
    });
  }

  return (
    <div>
      <Space>
        <Button onClick={onGenerateImage} type="link">Gen</Button>
        <Button onClick={onSearchImage} type="link">Search</Button>
      </Space>
      <Upload
        name='avatar'
        customRequest={customRequest}
        listType='picture-card'
        onChange={({fileList}) => {
          setFileList(fileList);
        }}
        fileList={fileList}
        onPreview={onPreview}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      {previewImage && <Image
        wrapperStyle={{display: 'none'}}
        preview={{
          visible: previewOpen,
          onVisibleChange: (visible) => setPreviewOpen(visible),
          afterOpenChange: (visible) => !visible && setPreviewImage(''),
        }}
        src={previewImage}
      />}
    </div>
  )
}

const columns = [{
  title: 'gmtCreate',
  key: 'gmtCreate',
  dataIndex: 'gmtCreate'
}, {
  title: 'podcastId',
  key: 'podcastId',
  dataIndex: 'podcastId'
}, {
  title: 'title',
  key: 'title',
  dataIndex: 'title',
  render: (title, item) => {
    let icon = null;
    if (item.status === 'READY') {
      icon = <CheckCircleTwoTone twoToneColor="#52c41a" />
    }
    return <Space>{icon}{title}</Space>
  }
}, {
  title: 'showNotes',
  key: 'showNotes',
  dataIndex: 'showNotes',
  render: (showNotes, item) => {
    showNotes = showNotes || '';
    return (
      <Tooltip title={showNotes}>
        <span>{showNotes.length > 10 ? `${showNotes.slice(0, 10)}...` :  showNotes}</span>
      </Tooltip>
    )
  }
}, {
  title: 'ossPath',
  key: 'ossPath',
  render: (value) => {
    return <a target='_blank' href={`https://cdn-pt.perterpon.com/lh-tts/audios/${value}`}>{value}</a>
  },
  dataIndex: 'ossPath'
}, {
  title: 'taskId',
  key: 'taskId',
  dataIndex: 'taskId'
}, {
  title: 'cover',
  key: 'cover',
  render: (item) => {
    return <RenderCover item={item}></RenderCover>
  }
}, {
  title: 'action',
  key: 'action',
  render: (item) => {
    const status = item?.publishTask?.status;
    let publishBtn = null;
    let buildBtn = null;
    let actionBtn = <></>;
    let segmentBtn = <></>;
    let doneBtn = <></>;
    switch (status) {
      case 'COMPLETE':
        publishBtn = (
          <div>
            <Button disabled={true} type='link' onClick={() => { onPublish(item) }}>publish</Button>
          </div>
        )
        break;
      case 'ERROR':
        publishBtn = (
          <div>
            <Button type='link' onClick={() => { onRetryPublish(item) }}>retry publish</Button>
          </div>
        )
        break;
      default:
        publishBtn = (
          <div>
            <Button type='link' onClick={() => { onPublish(item) }}>publish</Button>
          </div>
        )
    }

    if ('COMPLETE' === item?.buildTask?.status && null === item.ossPath) {
      buildBtn = (
        <div>
          <Button type='link' onClick={() => { onRetryBuilder(item) }}>retry build</Button>
        </div>
      )
    }

    function onEditorTitle(item) {
      const title = window.prompt('edit title', item.title);
      if (undefined !== title) {
        requestData('editPodcastTitle', {
          podcastId: item.podcastId,
          title: title
        });
      }
    }

    actionBtn = (
      <Button type='link' onClick={() => { onEditorTitle(item) }}>Edit Title</Button>
    );

    function onSegment(item) {
      Modal.confirm({
        content: <Segment audioId={item?.audio?.audioId} ></Segment>,
        width: 1300
      })
    }

    function onStatus(item, status) {
      requestData('updatePodcastStatus', {
        podcastId: item.podcastId,
        status
      });
    }

    if (item.audio) {
      segmentBtn = <Button type='link' onClick={() => { onSegment(item) }}>Segment</Button>
    }

    if (item.status === 'READY') {
      doneBtn = <Button type='link' onClick={() => {onStatus(item, 'CREATED')}}>unReady</Button>;
    } else {
      doneBtn = <Button type='link' onClick={() => {onStatus(item, 'READY')}}>ready</Button>;
    }

    return (
      <>
        {actionBtn}
        {publishBtn}
        {buildBtn}
        {segmentBtn}
        {doneBtn}
      </>
    )
  },
}];

async function onRetryPublish(item) {
  requestData('retryTask', {
    taskId: item.publishTaskId
  })
}

async function onRetryBuilder(item) {
  requestData('retryTask', {
    taskId: item.buildTaskId
  })
}

async function onPublish(item) {
  await requestData('generateTask', {
    type: 'PUBLISH',
    title: item.title,
    params: {
      podcastId: item.podcastId
    },
    cluster: 'script',
  });

}

function renderContent(record) {
  return <div>{record.content}</div>
}

function RenderList({ type }) {

  let pageSize = 200;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(0.1);
  const [pagination, setPagination] = useState({
    current:1, 
    pageSize,
    total: 10000
  });

  useEffect(() => {
    setLoading(true);
    requestData('getPodcast', { type, page, pageSize }).then((data) => {
      setLoading(false);
      let i = 0;
      for (const item of data) {
        item.key = i++;
      }
      setData(data);
    }).catch(() => {
      setLoading(false);
    });
  }, [page, reload]);

  const onChange = useCallback((page) => {
    setPage(page.current);
    setPagination(page);
  }, []);

  return (
    <div>
      <Button onClick={() => { setReload(Math.random()) }}>Reload</Button>
      <Table loading={loading} dataSource={data} onChange={onChange} columns={columns} pagination={pagination} expandable={{
        rowExpandable: () => true,
        expandedRowRender: renderContent
      }} />
    </div>
  );
}

export default () => {

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  
  useEffect(() => {
    setLoading(true);
    requestData('getPodcastTypes').then((data) => {
      const newItems = [];
      for (const type of data) {
        newItems.push({
          key: type,
          label: type,
          children: <RenderList type={type}></RenderList>
        });
      }
      setLoading(false);
      setItems(newItems);
      // let i = 0;
      // const itemMap = {};
      // for (const item of data) {
      //   item.key = i++;
      //   const { showType } = item;
      //   if (itemMap[showType] === undefined) {
      //     itemMap[showType] = [];
      //   }
      //   itemMap[showType].push(item);
      // }
      // const keys = Object.keys(itemMap);
      // const newItems = [{
      //   key: 'all',
      //   label: 'All',
      //   children: <RenderList listData={data}></RenderList>
      // }];
      // for (const key of keys) {
      //   newItems.push({
      //     key: key,
      //     label: key,
      //     children: <RenderList listData={itemMap[key]}></RenderList>
      //   });
      // }
      // setItems(newItems);
    }).catch((e) => {
      console.error(e);
      setLoading(false);
    });
  }, []);
  return (
    <div className="articles">
      <Spin spinning={loading}>
        <Tabs items={items}></Tabs>
      </Spin>
    </div>
  );
}
