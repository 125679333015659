import React, { Children, useCallback, useEffect, useState } from 'react';

import { Button, Input, TextArea, Select, Spin, Form, message, Space, Tabs, Table, Tag, Modal } from 'antd';
import * as _ from 'lodash';
import { CheckCircleTwoTone, TranslationOutlined } from '@ant-design/icons';

import { requestData } from './request';

// const fileSize = partial({ standard: 'jedec' });

let currentTab = null;

async function loadData() {
  const res = await requestData('getArticles');
  return res;
}

const columns = [{
  title: 'articleId',
  key: 'articleId',
  dataIndex: 'articleId'
}, {
  title: 'site',
  key: 'site',
  dataIndex: 'site'
}, {
  title: 'type',
  key: 'type',
  dataIndex: 'type'
}, {
  title: 'title',
  key: 'title',
  dataIndex: 'title',
  render: (title, item) => {
    let icon = null;
    if (item.content) {
      icon = <CheckCircleTwoTone twoToneColor="#52c41a" />
    } else if (item.translateTaskId) {
      icon = <TranslationOutlined style={{color: 'yellow'}} />
    }
    return (
      <>
        {icon}
        <span>{title}</span>
      </>
    )
  }
}, {
  title: 'publishDate',
  key: 'publishDate',
  dataIndex: 'publishDate'
}, {
  title: 'audioId',
  key: 'audioId',
  dataIndex: 'audioId'
}, {
  title: 'action',
  key: 'action',
  render: (item) => {
    return (
      <div>
        <Button disabled={item.translateTaskId !== null} type='link' onClick={onTranslate.bind(undefined, item)}>Translate</Button>
        <Button disabled={item.content === null} type='link' onClick={onCommon.bind(undefined, item)}>{currentTab}</Button>
      </div>
    )
  },
}];

async function onTranslate(record) {
  await requestData('translateArticle', {
    articleId: record.articleId
  });
}

async function onCommon(record) {
  if ('cxdt' === currentTab) {
    await onChooseArticle(record);
  } else if ('ted.com' === currentTab) {
    await onTED(record);
  } else {
    await requestData('generateAudioArticle', {
      articleId: record.articleId,
      type: currentTab
    });
  }
}

async function onQQ(record) {
  await requestData('generateAudioArticle', {
    articleId: record.articleId,
    type: 'czykssjyxx'
  });
}


async function onChooseArticle(record) {

  await requestData('addDailyArticle', {
    articleId: record.articleId,
  });

}

async function onTED(item) {
  await requestData('generateAudioArticle', {
    articleId: item.articleId,
    type: 'ted.article'
  });
}

function RenderContent({record}) {
  const [content, setContent] = useState(record.content);

  useEffect(() => {

    if (record.type === 'audio' && !record.translateTaskId) {
      setContent('no translate task found');
    } else {

      async function loadTranslateResult() {
        const data = await requestData('getArticleTranslateResult', {
          articleId: record.articleId
        })
        setContent(data);
      }

      loadTranslateResult();
    }

  }, [record]);


  return (
    <div>{content}</div>
  )
}

function RenderList({ site, showType }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = 300;
  const [pagination, setPagination] = useState({
    current:1, 
    pageSize,
    total: 10000
  });
  useEffect(() => {
    setLoading(true);
    requestData('getArticleBySite', { site, page, pageSize }).then((data) => {
      setLoading(false);
      let i = 0;
      for (const item of data) {
        item.key = i++;
      }
      setData(data);
    }).catch(() => {
      setLoading(false);
    });
  }, [page, currentTab]);

  const onChange = useCallback((page) => {
    setPage(page.current);
    setPagination(page);
  }, []);

  return (
    <Table loading={loading} dataSource={data} onChange={onChange} columns={columns} pagination={pagination} expandable={{
      rowExpandable: () => true,
      expandedRowRender: record => <RenderContent record={record} />
    }} />
  );
}

function AddArticle() {

  async function onAdd(values) {
    await requestData('addArticle', values);
  }

  return (
    <div>
      <Form initialValues={{site: 'qszt'}} onFinish={onAdd}>
        <Form.Item label="title" name="title" required>
          <Input />
        </Form.Item>
        <Form.Item label="content" name="content" required>
          <Input.TextArea className='article-content' />
        </Form.Item>
        <Form.Item label="site" name="site" required>
          <Select>
            <Select.Option value="qszt">qszt</Select.Option>
            <Select.Option value="deepThought">deepThought</Select.Option>
            <Select.Option value="figure">figure</Select.Option>
          </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </div>
  )
}

// 添加 GenArticle 组件
function GenArticle() {
  async function onSubmit(values) {
    await requestData('generateTask', {
      type: 'GENERATE_STORY',
      params: values,
      cluster: 'script',
    });
  }

  return (
    <div>
      <Form initialValues={{type: 'wheel'}} onFinish={onSubmit}>
        <Form.Item label="name" name="name" required>
          <Input />
        </Form.Item>
        <Form.Item label="type" name="type" required>
          <Select>
            <Select.Option value="wheel">wheel</Select.Option>
            <Select.Option value="story">story</Select.Option>
          </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          确认
        </Button>
      </Form>
    </div>
  )
}

function RenderSites({sites, showType}) {

  function onTabChange() {

  }

  let tabs = [];
  for (const site of sites) {
    const { name, type } = site;
    tabs.push({
      key: type,
      label: name,
      children: <RenderList showType={showType} site={type} />
    });
  }

  return (
    <Tabs items={tabs} onChange={onTabChange} />
  )

}

export default () => {

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setLoading(true);

    requestData('getArticleConfig').then((data) => {
      setLoading(false);
      const tabs = [];
      const tabMap = {};
      for (const site of data) {
        const { type, showType } = site;
        let showSites = tabMap[showType];
        if (false === _.isArray(showSites)) {
          showSites = [];
          tabMap[showType] = showSites;
        }
        showSites.push(site);
      }
      for (const showType in tabMap) {
        if (null === currentTab) {
          currentTab = showType;
        }
        tabs.push({
          key: showType,
          label: showType,
          children: <RenderSites showType={showType} sites={tabMap[showType]}></RenderSites>
        });
      }
      setItems(tabs)
    });

    // requestData('getSiteTypes').then((data) => {
    //   setLoading(false);
    //   const tabs = [];
    //   for (const site of data) {
    //     tabs.push({
    //       key: site,
    //       label: site,
    //       children: <RenderList site={site}></RenderList>
    //     });
    //   }
    //   setItems(tabs)
    // });
  }, []);

  async function onScraper() {
    await requestData('generateTask', {
      type: 'SCRAPER',
      title: `article scraper`,
      params: {
        dateTime: new Date()
      }
    });
  }

  async function onAddArticle() {
    Modal.info({
      content: <AddArticle />,
      width: 800
    });
  }
  
  // 添加 onGenArticle 函数
  async function onGenArticle() {
    Modal.info({
      title: "生成文章",
      content: <GenArticle />,
      width: 800
    });
  }

  const onTab = useCallback((tab) => {
    currentTab = tab;
  }, []);

  return (
    <div className="articles">
      <Button type='primary' onClick={() => {onScraper()}}>Scraper</Button>
      <Button onClick={() => { onAddArticle() }}>AddArticle</Button>
      <Button onClick={() => { onGenArticle() }}>GenArticle</Button>
      <Spin spinning={loading}>
        <Tabs onChange={onTab} items={items}></Tabs>
      </Spin>
    </div>
  );
}
